@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

.gold-animated-background {
    position:relative;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #d2a518;
    overflow: hidden;
}

.gold-animated-background span {
    width: 1vmin;
    height: 1vmin;
    border-radius: 1vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 50;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}


.gold-animated-background span:nth-child(0) {
    color: #d2a518;
    top: 32%;
    left: 89%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 12vw 21vh;
    box-shadow: -2vmin 0 0.8104014185441195vmin currentColor;
}
.gold-animated-background span:nth-child(1) {
    color: #c2c2c2;
    top: 58%;
    left: 56%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -24vw 13vh;
    box-shadow: 2vmin 0 0.5714043297879976vmin currentColor;
}
.gold-animated-background span:nth-child(2) {
    color: #d2a518;
    top: 32%;
    left: 89%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 0vw 2vh;
    box-shadow: 2vmin 0 0.9001802580383331vmin currentColor;
}
.gold-animated-background span:nth-child(3) {
    color: #3b415f;
    top: 41%;
    left: 65%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -24vw 18vh;
    box-shadow: -2vmin 0 1.1444999240965164vmin currentColor;
}
.gold-animated-background span:nth-child(4) {
    color: #c2c2c2;
    top: 37%;
    left: 5%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -18vw 8vh;
    box-shadow: 2vmin 0 0.9109176564674337vmin currentColor;
}
.gold-animated-background span:nth-child(5) {
    color: #d2a518;
    top: 51%;
    left: 80%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 12vw -17vh;
    box-shadow: 2vmin 0 0.7634519472507155vmin currentColor;
}
.gold-animated-background span:nth-child(6) {
    color: #c2c2c2;
    top: 18%;
    left: 65%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 5vw 23vh;
    box-shadow: -2vmin 0 0.3113950991971548vmin currentColor;
}
.gold-animated-background span:nth-child(7) {
    color: #d2a518;
    top: 44%;
    left: 11%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -20vw -23vh;
    box-shadow: 2vmin 0 1.0713024364056392vmin currentColor;
}
.gold-animated-background span:nth-child(8) {
    color: #c2c2c2;
    top: 62%;
    left: 16%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -18vw -1vh;
    box-shadow: 2vmin 0 0.9155116990224001vmin currentColor;
}
.gold-animated-background span:nth-child(9) {
    color: #3b415f;
    top: 64%;
    left: 39%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -22vw 11vh;
    box-shadow: -2vmin 0 0.4228721340643411vmin currentColor;
}
.gold-animated-background span:nth-child(10) {
    color: #3b415f;
    top: 85%;
    left: 83%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 14vw -20vh;
    box-shadow: -2vmin 0 0.7861394113673723vmin currentColor;
}
.gold-animated-background span:nth-child(11) {
    color: #3b415f;
    top: 86%;
    left: 13%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 11vw -24vh;
    box-shadow: 2vmin 0 1.2217473138032637vmin currentColor;
}
.gold-animated-background span:nth-child(12) {
    color: #d2a518;
    top: 93%;
    left: 59%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -21vw -12vh;
    box-shadow: -2vmin 0 0.8408938378026485vmin currentColor;
}
.gold-animated-background span:nth-child(13) {
    color: #d2a518;
    top: 27%;
    left: 80%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 12vw 25vh;
    box-shadow: -2vmin 0 0.2595911347327353vmin currentColor;
}
.gold-animated-background span:nth-child(14) {
    color: #c2c2c2;
    top: 92%;
    left: 10%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -2vw -19vh;
    box-shadow: 2vmin 0 1.2128072067070712vmin currentColor;
}
.gold-animated-background span:nth-child(15) {
    color: #d2a518;
    top: 58%;
    left: 38%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 18vw -1vh;
    box-shadow: -2vmin 0 0.9539619793328482vmin currentColor;
}
.gold-animated-background span:nth-child(16) {
    color: #d2a518;
    top: 15%;
    left: 41%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -1vw 6vh;
    box-shadow: -2vmin 0 0.4996681014871809vmin currentColor;
}
.gold-animated-background span:nth-child(17) {
    color: #d2a518;
    top: 81%;
    left: 88%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -21vw -15vh;
    box-shadow: 2vmin 0 0.7885303104726276vmin currentColor;
}
.gold-animated-background span:nth-child(18) {
    color: #d2a518;
    top: 55%;
    left: 43%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 14vw -23vh;
    box-shadow: 2vmin 0 0.29406532678992714vmin currentColor;
}
.gold-animated-background span:nth-child(19) {
    color: #c2c2c2;
    top: 35%;
    left: 68%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -2vw 1vh;
    box-shadow: 2vmin 0 1.04836335726091vmin currentColor;
}
.gold-animated-background span:nth-child(20) {
    color: #c2c2c2;
    top: 29%;
    left: 2%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 3vw -9vh;
    box-shadow: -2vmin 0 0.2730844941045505vmin currentColor;
}
.gold-animated-background span:nth-child(21) {
    color: #3b415f;
    top: 95%;
    left: 20%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -12vw 23vh;
    box-shadow: -2vmin 0 1.1411450098389222vmin currentColor;
}
.gold-animated-background span:nth-child(22) {
    color: #3b415f;
    top: 34%;
    left: 23%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -7vw 7vh;
    box-shadow: -2vmin 0 0.8473517150696923vmin currentColor;
}
.gold-animated-background span:nth-child(23) {
    color: #3b415f;
    top: 47%;
    left: 27%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -22vw -23vh;
    box-shadow: 2vmin 0 0.8674591275458845vmin currentColor;
}
.gold-animated-background span:nth-child(24) {
    color: #d2a518;
    top: 23%;
    left: 2%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -5vw 3vh;
    box-shadow: -2vmin 0 0.7118075619555151vmin currentColor;
}
.gold-animated-background span:nth-child(25) {
    color: #d2a518;
    top: 27%;
    left: 83%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 19vw 8vh;
    box-shadow: 2vmin 0 0.5946940367485782vmin currentColor;
}
.gold-animated-background span:nth-child(26) {
    color: #3b415f;
    top: 99%;
    left: 95%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -24vw 25vh;
    box-shadow: 2vmin 0 0.9820729802590931vmin currentColor;
}
.gold-animated-background span:nth-child(27) {
    color: #3b415f;
    top: 83%;
    left: 46%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 14vw 11vh;
    box-shadow: 2vmin 0 1.1302530266523472vmin currentColor;
}
.gold-animated-background span:nth-child(28) {
    color: #d2a518;
    top: 17%;
    left: 34%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 23vw -20vh;
    box-shadow: -2vmin 0 0.8452420775200427vmin currentColor;
}
.gold-animated-background span:nth-child(29) {
    color: #c2c2c2;
    top: 96%;
    left: 11%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -21vw -10vh;
    box-shadow: 2vmin 0 0.6569935314579465vmin currentColor;
}
.gold-animated-background span:nth-child(30) {
    color: #c2c2c2;
    top: 18%;
    left: 30%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 20vw -13vh;
    box-shadow: -2vmin 0 0.881963906118999vmin currentColor;
}
.gold-animated-background span:nth-child(31) {
    color: #3b415f;
    top: 84%;
    left: 20%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -2vw -10vh;
    box-shadow: 2vmin 0 0.47211897414599213vmin currentColor;
}
.gold-animated-background span:nth-child(32) {
    color: #c2c2c2;
    top: 14%;
    left: 42%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 22vw -6vh;
    box-shadow: -2vmin 0 0.9465881591400489vmin currentColor;
}
.gold-animated-background span:nth-child(33) {
    color: #3b415f;
    top: 16%;
    left: 4%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -6vw 3vh;
    box-shadow: 2vmin 0 0.25159380771598694vmin currentColor;
}
.gold-animated-background span:nth-child(34) {
    color: #d2a518;
    top: 6%;
    left: 97%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -21vw 21vh;
    box-shadow: -2vmin 0 0.6099270166973192vmin currentColor;
}
.gold-animated-background span:nth-child(35) {
    color: #3b415f;
    top: 95%;
    left: 18%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -2vw 0vh;
    box-shadow: -2vmin 0 0.9742132054265629vmin currentColor;
}
.gold-animated-background span:nth-child(36) {
    color: #d2a518;
    top: 64%;
    left: 40%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -4vw 2vh;
    box-shadow: 2vmin 0 0.7564765729891216vmin currentColor;
}
.gold-animated-background span:nth-child(37) {
    color: #d2a518;
    top: 32%;
    left: 49%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 10vw -20vh;
    box-shadow: -2vmin 0 0.9352297831441718vmin currentColor;
}
.gold-animated-background span:nth-child(38) {
    color: #3b415f;
    top: 94%;
    left: 100%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 3vw 18vh;
    box-shadow: -2vmin 0 1.1703749565951354vmin currentColor;
}
.gold-animated-background span:nth-child(39) {
    color: #c2c2c2;
    top: 57%;
    left: 45%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 20vw 25vh;
    box-shadow: -2vmin 0 1.182982917297063vmin currentColor;
}
.gold-animated-background span:nth-child(40) {
    color: #d2a518;
    top: 55%;
    left: 11%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -24vw -7vh;
    box-shadow: 2vmin 0 0.6154660015145446vmin currentColor;
}
.gold-animated-background span:nth-child(41) {
    color: #3b415f;
    top: 45%;
    left: 46%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -5vw 4vh;
    box-shadow: -2vmin 0 0.5357958946265109vmin currentColor;
}
.gold-animated-background span:nth-child(42) {
    color: #3b415f;
    top: 81%;
    left: 61%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 19vw -16vh;
    box-shadow: -2vmin 0 0.813411043318021vmin currentColor;
}
.gold-animated-background span:nth-child(43) {
    color: #d2a518;
    top: 21%;
    left: 55%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 19vw -13vh;
    box-shadow: 2vmin 0 0.9657106776146791vmin currentColor;
}
.gold-animated-background span:nth-child(44) {
    color: #3b415f;
    top: 77%;
    left: 74%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 2vw -16vh;
    box-shadow: -2vmin 0 0.39714283262644945vmin currentColor;
}
.gold-animated-background span:nth-child(45) {
    color: #d2a518;
    top: 77%;
    left: 97%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 8vw 11vh;
    box-shadow: 2vmin 0 1.0110101481717vmin currentColor;
}
.gold-animated-background span:nth-child(46) {
    color: #3b415f;
    top: 7%;
    left: 29%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 19vw 7vh;
    box-shadow: -2vmin 0 0.3992555622121532vmin currentColor;
}
.gold-animated-background span:nth-child(47) {
    color: #3b415f;
    top: 87%;
    left: 88%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: 9vw 19vh;
    box-shadow: -2vmin 0 0.556592969473005vmin currentColor;
}
.gold-animated-background span:nth-child(48) {
    color: #3b415f;
    top: 97%;
    left: 43%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -5vw -9vh;
    box-shadow: 2vmin 0 0.5857805464155643vmin currentColor;
}
.gold-animated-background span:nth-child(49) {
    color: #d2a518;
    top: 40%;
    left: 57%;
    animation-duration: 10s;
    animation-delay: -1s;
    transform-origin: -21vw 18vh;
    box-shadow: -2vmin 0 1.2392399572985968vmin currentColor;
}
